<template>
    <div class="field">
        <label class="label" :for="id">{{ label }}</label>
        <div class="input-wrapper">
            <!-- Multiselect Dropdown -->
            <vue-multiselect
                id="countries"
                :options="countries"
                v-model="selectedCountryPrefix"
                :allow-empty="false"
                :searchable="false"
                :disabled="disabled"
            >
                <!-- Display selected country -->
                <template slot="singleLabel" slot-scope="props">
                    <img
                        v-if="props.option.code !== 'unknown'"
                        :src="'/svg/icons/flags/' + props.option.code.toLowerCase() + '.svg'"
                        alt="country flag"
                    />
                    <span>{{ props.option.prefix || "Unknown" }}</span>
                </template>

                <!-- Display dropdown options -->
                <template slot="option" slot-scope="props">
                    <div class="option__desc">
                        <img
                            v-if="props.option.code !== 'unknown'"
                            :src="
                                '/svg/icons/flags/' + props.option.code.toLowerCase() + '.svg'
                            "
                            alt="country flag"
                        />
                        <span class="option__title">{{
                            props.option.prefix || "Unknown"
                        }}</span>
                    </div>
                </template>
            </vue-multiselect>

            <!-- Editable phone number -->
            <input
                :id="id"
                class="phone-input"
                v-model="parsedPhoneNumber"
                type="text"
                :placeholder="'123 456 789'"
                :disabled="disabled"
            />
        </div>
        <p class="error" v-if="!phoneNumberIsValid && !!parsedPhoneNumber && !disabled">
            {{ $t("phone_number.invalid_phone") }}
        </p>
    </div>
</template>
<script>
import VueMultiselect from "vue-multiselect";
import {
    parsePhoneNumber,
    AsYouType,
    CountryCode,
    isValidPhoneNumber,
} from "libphonenumber-js";

export default {
    components: {
        VueMultiselect,
    },
    props: {
        id: {
            type: String,
            default: "",
        },
        label: {
            type: String,
            default: "",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        value: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            selectedCountryPrefixRaw: null,
            phoneNumber: "",
            internationalizedPhoneNumber: "",
            phoneNumberIsValid: false,
            unknownCountry: {
                prefix: "",
                code: "unknown", // Placeholder for unknown numbers
            },
            countries: [
                { prefix: "+31", code: "NL" },
                { prefix: "+32", code: "BE" },
                { prefix: "+33", code: "FR" },
                { prefix: "+34", code: "ES" },
                { prefix: "+36", code: "HU" },
                { prefix: "+40", code: "RO" },
                { prefix: "+41", code: "CH" },
                { prefix: "+44", code: "GB" },
                { prefix: "+48", code: "PL" },
                { prefix: "+49", code: "DE" },
                { prefix: "+359", code: "BG" },
                { prefix: "+370", code: "LT" },
                { prefix: "+371", code: "LV" },
                { prefix: "+373", code: "MD" },
            ],
            defaultCountry: { prefix: "+31", code: "NL" },
        };
    },
    methods: {
        formatPhoneNumber(value) {
            if (!value) {
                this.$emit("phoneNumber", "");
                this.$emit("input", "");
                return;
            }

            // Check if the number starts with a plus sign to determine if it has a country code
            const hasCountryCode = value.startsWith('+');

            const countryCode = this.selectedCountryPrefix?.code || "UNKNOWN";
            const formatter = new AsYouType(countryCode);
            const formattedNumber = formatter.input(value);

            this.phoneNumber = formattedNumber;

            // Always emit the full number with prefix for consistency
            const fullNumber = hasCountryCode ? value : this.selectedCountryPrefix?.prefix + this.stripPrefix(value);
            this.$emit("input", fullNumber);

            // Only validate if the number has a country code
            this.phoneNumberIsValid = hasCountryCode ? isValidPhoneNumber(fullNumber, countryCode) : true;

            // Emit formatted international number only if valid and has country code
            if (this.phoneNumberIsValid && hasCountryCode) {
                const parsed = parsePhoneNumber(fullNumber, countryCode);
                this.internationalizedPhoneNumber = parsed.formatInternational();
                this.$emit("phoneNumber", this.internationalizedPhoneNumber);
            } else {
                this.$emit("phoneNumber", fullNumber);
            }
        },
        stripPrefix(value) {
            if (!value) return "";
            const escapedPrefix = this.selectedCountryPrefix?.prefix.replace(
                "+",
                "\\+"
            );
            const regex = new RegExp(`^${escapedPrefix}`);
            return value.replace(regex, "").trim();
        },
    },
    watch: {
        selectedCountryPrefix: {
            immediate: true,
            deep: true,
            handler() {
                this.formatPhoneNumber(this.value);
            },
        },
        value: {
            immediate: true,
            handler(value) {
                if (value) {
                    // Only try to find country if the number starts with a plus sign
                    if (value.startsWith('+')) {
                        const country = this.countries.find((country) =>
                            value.startsWith(country.prefix)
                        );
                        this.selectedCountryPrefix =
                            country ||
                            this.selectedCountryPrefixRaw ||
                            this.unknownCountry;
                    } else {
                        // Set to unknown country if no country code is present
                        this.selectedCountryPrefix = this.unknownCountry;
                    }
                    this.phoneNumber = this.stripPrefix(value);
                }
            },
        },
    },
    computed: {
        selectedCountryPrefix: {
            get() {
                const country = this.countries.find((country) =>
                    this.value?.startsWith(country.prefix)
                );
                return (
                    this.selectedCountryPrefixRaw ||
                    country ||
                    this.unknownCountry
                );
            },
            set(value) {
                this.selectedCountryPrefixRaw = value;
            },
        },
        parsedPhoneNumber: {
            get() {
                return this.stripPrefix(this.value);
            },
            set(value) {
                this.formatPhoneNumber(value);
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.input-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;

    ::v-deep .multiselect {
        flex-grow: 0;
        width: auto;

        .multiselect__select {
            top: 5px;
        }

        .multiselect__tags {
            min-height: 52px;

            .multiselect__single {
                display: flex;
                align-items: center;
                gap: 5px;
            }
        }

        .multiselect__content-wrapper {
            border: 1px solid var(--color-grey-100);
            background-color: var(--color-white);

            .multiselect__content {
                .multiselect__option {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    color: var(--color-black);

                    .option__desc {
                        display: flex;
                        align-items: center;
                        gap: 5px;
                    }
                }

                .multiselect__option--highlight,
                .multiselect__option--selected {
                    background-color: var(--color-grey-100);

                    &::after {
                        display: none;
                    }
                }
            }
        }
    }

    .phone-input {
        flex-grow: 1;
        border: 1px solid var(--color-grey-100);
        border-radius: 4px;
        font-size: 1rem;
        color: var(--color-black);

        &:focus {
            border-color: var(--color-primary);
            outline: none;
        }
    }
}

.error {
    color: red;
    font-weight: bold;
}
</style>
