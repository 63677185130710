const state = {
    article: {},
    language: {},
    allArticlesTitles: [],
    selectedTags: [],
};

const getters = {
    article: state => state.article,
    language: state => state.language,
    components_by_article_id: state => (state.article.components ? state.article.components.sort((a, b) => a.position - b.position) : state),
};

const actions = {
    set_language({ commit, state }, { language }) {
        commit('set_language', { language: language });
    },
    set_article_title({ commit, state }, { title, language }) {
        commit('set_article_title', { title, language });
        commit('save', { state });
    },
    set_article_image({ commit, state }, { image }) {
        commit('set_article_image', { state, image });
        commit('save', { state });
    },
    set_article_publish_at_date({ commit, state }, { date }) {
        commit('set_article_publish_at_date', date);
        commit('save', { state });
    },
    set_related_articles({ commit, state }, { articles }) {
        commit('set_related_articles', articles);
        commit('save', { state });
    },
    set_profile_description({ commit, state }, { description }) {
        commit('set_profile_description', description);
        commit('save', { state });
    },
    set_divisions_and_functions({ commit, state }, { divisions, functions }) {
        commit('set_divisions_and_functions', { divisions, functions });
        commit('save', { state });
    },
    remove_article_image({ commit, state }) {
        window.axios.delete(`/articles/${state.article.id}/featured-image`);
        commit('remove_article_image');
    },
    swap_component_positions({ commit, state }, { oldComponentId, newComponentId }) {
        commit('swap_components', { oldComponentId, newComponentId });
        window.axios.put(`/components/swap-positions`, {
            'old': oldComponentId,
            'new': newComponentId,
        });
    },
    set_components({ commit, state }, { components }) {
        commit('update_article_component_position', { components: components });

        let componentPositions = components.map(function (component) {
            return {
                'id': component.id,
                'pos': component.position,
            };
        });

        window.axios.put(`/components/swap-positions`, componentPositions);
    },
    set_selected_tags({ commit, state }, { selectedTags }) {
        commit('set_selected_tags', selectedTags);
        commit('save', { state });
    },
    update_component({ commit }, component) {
        window.axios.put(`/components/${component.id}`, {
            content: component.content,
            pdfName: component.pdfName || null,
            language: component.language,
        }).then(response => {
            commit('update_component_content', {
                id: response.data.id,
                content: response.data.translations.find(t => t.language === component.language.code).content,
                language: component.language,
            });
        });
    },
    delete_component({ commit }, { chapterKey, id }) {
        window.axios.delete(`/components/${id}`).then(() => {
            commit('delete_component', {
                id: id,
            });
        });
    },
    store_empty_component({ commit }, component) {
        const components = state.article.components;
        const afterComponent = components.find(c => c.id === component.afterId);

        const promise = window.axios.post(`/components`, {
            componentable_id: component.data_id,
            componentable_type: component.componentable_type,
            component_type_id: component.component_type_id,
            ...(afterComponent ? { position: afterComponent.position } : {}),
        });

        promise.then(response => {
            commit('add_component', {
                component: response.data,
            });

            if (afterComponent) {
                commit('reposition_components_after_component', {
                    component: response.data,
                });
            }
        });

        return promise;
    },
};

const mutations = {
    set_language(state, language) {
        state.language = language;
    },
    save(state) {
        if (state.article.id === undefined) {
            return;
        }
        // We want to autosave all the time, even if there is no published_at
        // leaving it here commented for a future refactor

        // if(state.article.published_at) {
        //     return;
        // }

        window.axios.put('/articles/' + state.article.id + '/save', {
            translations: state.article.translations,
            featured_image: state.article.featured_image,
            articles: state.article.articles,
            profile_title: state.article.profile_title,
            profile_description: state.article.profile_description,
            profile_user_id: state.article.profile_user_id,
            story_user_id: state.article.story_user_id,
            business_units: state.article.business_units,
            employee_functions: state.article.employee_functions,
            publish_at: state.article.publish_at,
            language: state.language,
            tags: state.selectedTags,
        }).then((response) => {
            const featuredImage = response.data.featured_image;
            const randomImageVersion = Math.random().toString(36).substring(5);
            state.article.featured_image = !featuredImage ? '' : featuredImage + '?' + randomImageVersion;
        });
    },
    set_article(state, article) {
        state.article = article;
        const randomImageVersion = Math.random().toString(36).substring(5);
        state.article.featured_image = !state.article.featured_image ? '' : state.article.featured_image + '?' + randomImageVersion;
    },
    set_article_title(state, content) {
        if (content) {
            let translation = state.article.translations.filter(function (translations) {
                return translations.language === content.language.code;
            })[0];
            if (translation.content !== content.title) {
                translation.content = content.title;
            }
        }
    },
    set_article_published_at_date(state, date) {
        state.article.published_at = date;
    },
    set_article_publish_at_date(state, date) {
        state.article.publish_at = date;
    },
    set_related_articles(state, articles) {
        state.article.articles = articles;
    },
    set_profile_description(state, description) {
        state.article.profile_description = description;
    },
    set_profile_title(state, title) {
        state.article.profile_title = title;
    },
    set_profile_user_id(state, id) {
        state.article.profile_user_id = id;
    },
    set_story_user_id(state, id) {
        state.article.story_user_id = id;
    },
    set_divisions_and_functions(state, { divisions, functions }) {
        state.article.business_units = divisions;
        state.article.employee_functions = functions;
    },
    set_article_image(state, image) {
        if (image.image !== null) {
            state.article.featured_image = image.image.result;
        } else {
            state.article.featured_image = '';
        }
    },
    remove_article_image(state) {
        state.article.featured_image = '';
    },
    update_article_component_position(state, { components }) {
        state.article.components = components;
    },
    swap_components(state, { oldComponentId, newComponentId }) {
        const article = state.article;
        const i = article.components.findIndex(c => c.id === oldComponentId);
        const k = article.components.findIndex(c => c.id === newComponentId);

        let tmp = article.components[i].position;
        article.components[i].position = article.components[k].position;
        article.components[k].position = tmp;
    },
    update_component_content(state, { id, content, language }) {
        let article = state.article;
        let component = article.components.find(c => c.id === id);

        if (language.code) {
            let translation = component.translations.filter(function (translations) {
                return translations.language === language.code;
            })[0];

            translation.content = content;
        }
    },
    delete_component(state, { id }) {
        let article = state.article;
        let components = article.components;

        const i = components.findIndex(c => c.id === id);
        components.splice(i, 1);
    },
    add_component(state, { component }) {
        let article = state.article;
        let components = article.components;

        components.push(component);
    },
    reposition_components_after_component(state, { component }) {
        let article = state.article;

        article.components.forEach((cmp, i) => {
            if (cmp.position >= component.position && cmp.id !== component.id) {
                article.components[i].position++;
            }
        });
    },
    set_all_article_titles(state, articles) {
        state.allArticlesTitles = articles;
    },
    set_selected_tags(state, selectedTags) {
        state.selectedTags = selectedTags;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
